import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const initData = (fields, initialData) => {
    let theData = {}
    fields.forEach((field) => {
        if (field.default) { // used for create
            theData[field.key] = field.default;
        }
        if (initialData && initialData[field.key]) { // used for edit
            theData[field.key] = initialData[field.key];
        }
    });
    return theData;
}

export function SimplePopupForm({buttonTitle, popupTitle, popupIntroText, fields, entryData, saveButtonTitle, saveCallback, buttonType}) {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [data, setData] = React.useState(initData(fields, entryData));

    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const saveClicked = () => {
        saveCallback(data);
        handleClose();
    }

    const cancelRecord = () => {
        handleClose();
    }

    const handleClose = () => {
        setDialogOpen(false);
    };

    const setValue = (key, value) => {
        let updatedData = {
            ...data,
        };
        updatedData[key] = value;
        setData(updatedData);
    }

    let buttonOfType = <></>;
    if (buttonType === 'full') {
        buttonOfType = (<>
            <div>
                <Button variant="contained" onClick={handleClickOpen} sx={{mt: 2, mb: 0}}>
                    {buttonTitle}
                </Button>
            </div>
        </>);
    } else if (buttonType === 'icon') {
        buttonOfType = (<>
            <IconButton variant="contained" onClick={handleClickOpen}>
                <EditIcon />
            </IconButton>
        </>);
    }

    return (
        // div for layout, otherwise the button is full width
        <>
            {buttonOfType}
            <Dialog open={dialogOpen} onClose={handleClose}>
            <DialogTitle>{popupTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {popupIntroText}
                    </DialogContentText>
                    {(fields || []).map((field) => (
                        <TextField
                            key={field.key}
                            autoFocus
                            margin="dense"
                            id={field.key}
                            label={field.name}
                            type={field.type}
                            fullWidth
                            variant="standard"
                            value={data[field.key] || ''}
                            onChange={(ev) => {
                                setValue(field.key, ev.target.value)
                            }}
                        />
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelRecord}>Cancel</Button>
                    <Button variant="contained" onClick={saveClicked}>{saveButtonTitle}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
