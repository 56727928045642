import * as React from 'react';
import {SimplePopupForm} from "./SimplePopupForm";

export function SimpleNewEntryForm({modelName, fields, saveCallback}) {
    return (
        <>
            <SimplePopupForm
                buttonTitle={"Create New " + modelName}
                popupTitle={"Create New " + modelName}
                popupIntroText={"Enter all required data for the new " + modelName + " here:"}
                fields={fields}
                buttonType={"full"}
                saveButtonTitle={'Create'}
                saveCallback={saveCallback}
            />
        </>
    );
}
