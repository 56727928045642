import axios from "axios";

import config from "../Config";
import {getAuthHeader} from "./authentication";

export function fetchRunningProcesses(successCallback, errorCallback) {
    let url = `${config.API_BASE_URL}/v1/models/running_processes`
    axios
        .get(url, getAuthHeader())
        .then((response) => {
            successCallback(response);
        })
        .catch((error) => {
            errorCallback(error);
        });
}

export function getRecords(tenantId, userId, model, successCallback, errorCallback) {
    const url = tenantId == null ? (
        userId == null ?
            `${config.API_BASE_URL}/v1/models/${model}s` :
            `${config.API_BASE_URL}/v1/models/users/${userId}/${model}s`
    ) : `${config.API_BASE_URL}/v1/models/tenants/${tenantId}/${model}s`;
    axios
        .get(url, getAuthHeader())
        .then((response) => {
            successCallback(response);
        })
        .catch((error) => {
            errorCallback(error);
        });
}

export function createRecord(data, tenantId, userId, model, successCallback, errorCallback) {
    const url = tenantId == null ? (
        userId == null ?
            `${config.API_BASE_URL}/v1/models/${model}s` :
            `${config.API_BASE_URL}/v1/models/users/${userId}/${model}s`
    ) : `${config.API_BASE_URL}/v1/models/tenants/${tenantId}/${model}s`;
    axios
        .post(url, data, getAuthHeader())
        .then((response) => {
            successCallback(response);
        })
        .catch((error) => {
            errorCallback(error);
        });
}

export function updateRecord(data, tenantId, userId, model, recordId, successCallback, errorCallback) {
    const url = tenantId == null ? (
        userId == null ?
            `${config.API_BASE_URL}/v1/models/${model}s/${recordId}` :
            `${config.API_BASE_URL}/v1/models/users/${userId}/${model}s/${recordId}`
    ) : `${config.API_BASE_URL}/v1/models/tenants/${tenantId}/${model}s/${recordId}`;
    axios
        .put(url, data, getAuthHeader())
        .then((response) => {
            successCallback(response);
        })
        .catch((error) => {
            errorCallback(error);
        });
}

export function toggleScope(userId, tokenId, scope, successCallback, errorCallback) {
    const url = `${config.API_BASE_URL}/v1/models/users/${userId}/tokens/${tokenId}/toggle_scope`;
    const data = { scope };

    axios
        .post(url, data, getAuthHeader())
        .then((response) => {
            successCallback(response);
        })
        .catch((error) => {
            errorCallback(error);
        });
}

export function invalidateToken(userId, tokenId, successCallback, errorCallback) {
    const url = `${config.API_BASE_URL}/v1/models/users/${userId}/tokens/${tokenId}/invalidate`;
    const data = { };

    axios
        .post(url, data, getAuthHeader())
        .then((response) => {
            successCallback(response);
        })
        .catch((error) => {
            errorCallback(error);
        });
}

export function updateCoreFacts(tenantId, knowledgeBaseId, isCoreFacts, successCallback, errorCallback) {
    let url = `${config.API_BASE_URL}/v1/models/tenants/${tenantId}/knowledge_bases/${knowledgeBaseId}`;
    const data = {
        "has_core_facts": isCoreFacts,
    };
    axios
        .put(url, data, getAuthHeader())
        .then((response) => {
            successCallback(response);
        })
        .catch((error) => {
            errorCallback(error);
        });
}

export function deleteTenant(tenantId, successCallback, errorCallback) {
    let url = `${config.API_BASE_URL}/v1/models/tenants/${tenantId}`;
    axios
        .delete(url, getAuthHeader())
        .then((response) => {
            successCallback(response);
        })
        .catch((error) => {
            errorCallback(error);
        });
}


export async function downloadExcelFacts(tenantId, knowledgeBaseId, errorCallback) {
    const url = `${config.API_BASE_URL}/v1/models/tenants/${tenantId}/knowledge_bases/${knowledgeBaseId}/facts.xlsx`;
    try {
        let authConfig = getAuthHeader()
        authConfig.responseType = 'blob'
        const response = await axios.get(url, authConfig);
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const downloadUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = 'facts.xlsx';
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(() => {
            URL.revokeObjectURL(downloadUrl);
        }, 100);
    } catch (error) {
        errorCallback(error);
    }
}

export function uploadExcelFacts(tenantId, knowledgeBaseId, file, successCallback, errorCallback) {
    const url = `${config.API_BASE_URL}/v1/models/tenants/${tenantId}/knowledge_bases/${knowledgeBaseId}/facts.xlsx`;
    const formData = new FormData();
    formData.append('file', file);
    axios
        .post(url, formData, getAuthHeader())
        .then(response => {
            successCallback(response);
        })
        .catch(error => {
            errorCallback(error)
        });
}

export function getTTSResult(text, successCallback, errorCallback) {
    const url = `${config.API_BASE_URL}/v1/business/create_tts`;
    const data = {
        "text_to_speak": text,
        "voice": "onyx"
    };
    axios
        .post(url, data, {
            ...getAuthHeader(),
            responseType: "blob",
        })
        .then((response) => {
            const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
            successCallback(audioBlob);
        })
        .catch((error) => {
            errorCallback(error);
        });
}

export function getSTTResult(audioBlob, successCallback, errorCallback) {
    const url = `${config.API_BASE_URL}/v1/business/create_stt`;
    let fileExtension;
    switch (audioBlob.type) {
        case 'audio/mpeg':
        case 'audio/mp3':
            fileExtension = 'mp3'; // could also be mpeg
            break;
        case 'audio/mp4':
        case 'video/mp4':
            fileExtension = 'mp4';
            break;
        case 'audio/mpga':
            fileExtension = 'mpga';
            break;
        case 'audio/x-m4a':
        case 'audio/m4a':
            fileExtension = 'm4a';
            break;
        case 'audio/wav':
            fileExtension = 'wav';
            break;
        case 'audio/webm':
            fileExtension = 'webm';
            break;
        default:
            console.warn(`Unrecognized audio type: ${audioBlob.type}. File may not be processed correctly.`);
            fileExtension = 'bin';
    }

   const formData = new FormData();
    formData.append('audio_file', audioBlob, `audio.${fileExtension}`);
    axios
        .post(url, formData, getAuthHeader({'Content-Type': 'multipart/form-data'}))
        .then((response) => {
            successCallback(response.data.transcript);
        })
        .catch((error) => {
            errorCallback(error);
        });
}
