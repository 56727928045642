import * as React from 'react';

import {Switch} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';

import DataTable from "../components/DataTable";
import {createRecord, deleteTenant, updateRecord} from "../../util/requests";
import {SimpleEditEntryForm} from "../sub_views/SimpleEditEntryForm";


export default function Tenants({activeTenant, setActiveTenant, setActiveTenantName}) {
    let titles = [
        "ID",
        "Name",
        // "Description",
        "Knowledge Bases",
        "Prompt Collections",
        "Website Configs",
        "Models",
        "Actions",
    ];
    const tenantFields = [
        {
            "name": "Name",
            "key": "name",
        },
        {
            "name": "Description",
            "key": "description",
        },
        {
            "name": "Bot Name",
            "key": "bot_name",
        },
        {
            "name": "Trade (Branche)",
            "key": "branche",
        },
        {
            "name": "Company Name (Firma)",
            "key": "firma",
        },
        {
            "name": "Website",
            "key": "website",
        },
        {
            "name": "Info Mail Address",
            "key": "info_mail",
        },
    ];
    let actions = (record) => {
        const canTrash = record.knowledge_base_count === 0 &&
            record.custom_prompt_collection_count === 0 &&
            record.website_config_count === 0 &&
            record.model_count === 0;
        const trashButton = <>
            <IconButton onClick={() => {
                deleteTenant(record.id, (response) => {
                    setActiveTenant(null);
                }, (error) => {
                    console.error(error);
                })
            }}>
                <DeleteIcon/>
            </IconButton>
        </>;
        const editButton = (
            <>
                <SimpleEditEntryForm
                    modelName={"Tenant"}
                    entryID={record.id}
                    entryData={record}
                    fields={tenantFields}
                    buttonType={"icon"}
                    saveCallback={(data) => {
                        updateRecord(data, null, null, 'tenant', record.id, (response) => {
                            console.log(response);
                        }, (error) => {
                            console.error(error);
                        })
                    }}
                />
            </>
        );
        if (record.id === activeTenant) {
            return (
                <>
                    <Switch checked={true}/>
                    {editButton}
                    {canTrash ? trashButton : <></>}
                </>
            );
        } else {
            return (
                <>
                    <Switch checked={false} onChange={() => {
                        setActiveTenant(record.id)
                        setActiveTenantName(record.name);
                    }}/>
                    {editButton}
                    {canTrash ? trashButton : <></>}
                </>
            );
        }
    }
    let valueCallback = (title, record) => {
        switch (title) {
            case "ID":
                return record.id;
            case "Name":
                return record.name;
            case "Description":
                return record.description;
            case "Knowledge Bases":
                return record.knowledge_base_count;
            case "Prompt Collections":
                return record.custom_prompt_collection_count;
            case "Website Configs":
                return record.website_config_count;
            case "Models":
                return record.model_count;
            case "Actions":
                return actions(record);
            default:
                return "...";
        }
    };

    return (
        <>
            <DataTable
                modelName={"Tenant"}
                tenantId={null}
                model={"tenant"}
                titles={titles}
                valueCallback={valueCallback}
                allowsCreate={true}
                simpleCreateFields={tenantFields}
            />
        </>
    );
}
