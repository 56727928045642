import * as React from 'react';
import { useState, useEffect } from 'react';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ApartmentIcon from '@mui/icons-material/Apartment';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import StorageIcon from '@mui/icons-material/Storage';
// import ListAltIcon from '@mui/icons-material/ListAlt';
import WebIcon from '@mui/icons-material/Web';
// import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import EngineeringIcon from '@mui/icons-material/Engineering';
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import PeopleIcon from '@mui/icons-material/People';
import TokenIcon from '@mui/icons-material/Token';

import Divider from "@mui/material/Divider";
import LinearProgress from '@mui/material/LinearProgress';

import {fetchRunningProcesses} from "../util/requests";
import {sessionIsAdmin, sessionIsLoggedIn} from "../util/session_manager";

export function MenuSidebar(activeArea, setActiveArea, activeTenant, activeTenantName, activeModel, activeUser) {
    const [runningProcesses, setRunningProcesses] = useState([]);
    const isLoggedIn = sessionIsLoggedIn();

    useEffect(() => {
        if (isLoggedIn) {
            const successCallback = (response) => {
                setRunningProcesses(response.data);
            }
            const errorCallback = (error) => {
                console.error(error);
            }
            fetchRunningProcesses(successCallback, errorCallback);
            const interval = setInterval(() => {
                fetchRunningProcesses(successCallback, errorCallback);
            }, 5000);
            return () => clearInterval(interval);
        }
    }, [isLoggedIn]);

    if (!isLoggedIn) {
        return (
            <ListSubheader component="div" inset>
                Please log in
            </ListSubheader>
        )
    }

    let runningProcessesDisplay = <>
        <ListItemButton disabled={true}>
            <ListItemIcon>
                <HourglassDisabledIcon/>
            </ListItemIcon>
            <ListItemText primary="none"/>
        </ListItemButton>
    </>;
    if (Array.isArray(runningProcesses) && runningProcesses.length >= 1) {
        runningProcessesDisplay = []
        runningProcesses.forEach((runningProcess) => {
            runningProcessesDisplay.push(
                <>
                    <ListItemButton>
                        <ListItemIcon>
                            <EngineeringIcon/>
                        </ListItemIcon>
                        <ListItemText primary={runningProcess.description}/>
                    </ListItemButton>
                    <LinearProgress
                        variant="determinate"
                        value={runningProcess.progress}
                        sx={{width: '80%', marginLeft: 2, marginRight: 2}}
                    />
                </>
            );
        });
    }

    let adminArea = <></>;
    if (sessionIsAdmin()) {
        adminArea = (
            <>
                <ListSubheader component="div" inset>
                    Admin Area
                </ListSubheader>

                <ListItemButton onClick={() => { setActiveArea("User Admin") }}>
                    <ListItemIcon>
                        <PeopleIcon/>
                    </ListItemIcon>
                    <ListItemText primary="User Admin"/>
                </ListItemButton>

                <ListItemButton disabled={activeUser === null}  onClick={() => { setActiveArea("Token Admin") }}>
                    <ListItemIcon>
                        <TokenIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Token Admin"/>
                </ListItemButton>

                <Divider sx={{my: 1}}/>
            </>
        );
    }

    return (
        <>
            <ListSubheader component="div" inset>
                {activeTenantName === null ? "Choose Tenant" : activeTenantName}
            </ListSubheader>

            <ListItemButton onClick={() => { setActiveArea("Tenants") }}>
                <ListItemIcon>
                    <ApartmentIcon/>
                </ListItemIcon>
                <ListItemText primary="Tenants"/>
            </ListItemButton>

            <ListItemButton disabled={activeTenant === null} onClick={() => { setActiveArea("Knowledge Bases") }}>
                <ListItemIcon>
                    <MenuBookIcon/>
                </ListItemIcon>
                <ListItemText primary="Knowledge Bases"/>
            </ListItemButton>

            {/*<ListItemButton disabled={activeTenant === null} onClick={() => { setActiveArea("Prompt Collections") }}>*/}
            {/*    <ListItemIcon>*/}
            {/*        <ListAltIcon/>*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText primary="Prompt Collections"/>*/}
            {/*</ListItemButton>*/}

            <ListItemButton disabled={activeTenant === null} onClick={() => { setActiveArea("Website Configs") }}>
                <ListItemIcon>
                    <WebIcon/>
                </ListItemIcon>
                <ListItemText primary="Website Configs"/>
            </ListItemButton>

            <ListItemButton disabled={activeTenant === null} onClick={() => { setActiveArea("Models") }}>
                <ListItemIcon>
                    <StorageIcon/>
                </ListItemIcon>
                <ListItemText primary="Models"/>
            </ListItemButton>

            <Divider sx={{my: 1}}/>

            <ListSubheader component="div" inset>
                {activeModel === null ? "Choose Model" : "Testing"}
            </ListSubheader>

            <ListItemButton disabled={activeModel === null} onClick={() => { setActiveArea("Test Conversation") }}>
                <ListItemIcon>
                    <SpeakerNotesIcon/>
                </ListItemIcon>
                <ListItemText primary="Test Conversation"/>
            </ListItemButton>

            <Divider sx={{my: 1}}/>

            {adminArea}

            <ListSubheader component="div" inset>
                Processes
            </ListSubheader>

            {runningProcessesDisplay}
        </>
    );
}
