import * as React from 'react';
import {SimplePopupForm} from "./SimplePopupForm";

export function SimpleEditEntryForm({modelName, entryID, fields, entryData, buttonType, saveCallback}) {
    return (
        <>
            <SimplePopupForm
                buttonTitle={"Edit " + modelName + " (" + entryID + ")"}
                popupTitle={"Edit " + modelName + " (" + entryID + ")"}
                popupIntroText={"Update data for the " + modelName + " here:"}
                fields={fields}
                entryData={entryData}
                buttonType={buttonType}
                saveButtonTitle={'Update'}
                saveCallback={saveCallback}
            />
        </>
    );
}
