import * as React from 'react';
import ReactMarkdown from 'react-markdown';

import {useRef, useEffect, useMemo} from "react";

import {TextField, Card, CircularProgress} from "@mui/material";

import {getConversationResponse} from "../../util/chatbot";
import {getSTTResult, getTTSResult} from "../../util/requests";
import AudioRecorder from "../components/AudioRecorder";


export default function TestConversation({activeTenant, activeModel}) {
    const scrollRef = useRef(null);
    const [currentPrompt, setCurrentPrompt] = React.useState("");
    const [conversation, setConversation] = React.useState([]);
    const [recommendedUrls, setRecommendedUrls] = React.useState([])

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behaviour: "smooth" });
        }
    }, [conversation]);

    const audioElements = useMemo(() => {
        return conversation.reduce((acc, item, index) => {
            if (item.audio) {
                acc[index] = (
                    <audio
                        key={index}
                        controls
                        src={URL.createObjectURL(item.audio)}
                        onEnded={(e) => URL.revokeObjectURL(e.target.src)}
                    />
                );
            }
            return acc;
        }, {});
    }, [conversation]);

    const getCompletion = (request_uuid, prompt, errors = []) => {
        getConversationResponse(activeTenant, activeModel, prompt, conversation, request_uuid, (response) => {
            let updatedConversation = [];
            conversation.forEach((conversationItem) => {
                if (conversationItem["request_uuid"] === request_uuid) {
                    conversationItem["response"] = response.data.response;
                }
                updatedConversation.push(conversationItem);
            })
            setConversation(updatedConversation);
            setRecommendedUrls(response.data.urls);
            getTTSResult(response.data.response, (audioBlob) => {
                let updatedConversation = [];
                conversation.forEach((conversationItem) => {
                    if (conversationItem["request_uuid"] === request_uuid) {
                        conversationItem["audio"] = audioBlob;
                    }
                    updatedConversation.push(conversationItem);
                })
                setConversation(updatedConversation);
            }, (ttsError) => {
                console.error(ttsError);
            })
        }, (error) => {
            console.error(error);
            errors.push(error);
            if (errors.length < 5) {
                getCompletion(request_uuid, prompt, errors);
            }
        })
    };

    const handleAudioRecorded = (audioBlob) => {
        console.log('Audio recorded:', audioBlob);
        getSTTResult(audioBlob, (transcript) => {
            triggerPromptCompletion(transcript);
        }, (error) => {
            console.error(error);
        })
    };

    function triggerPromptCompletion(userInput) {
        let request_uuid = '';
        const first13 = Math.floor(Math.random() * Math.pow(10, 13)).toFixed(0).padStart(13, "0");
        const next13 = Math.floor(Math.random() * Math.pow(10, 13)).toFixed(0).padStart(13, "0");
        request_uuid = first13 + next13;
        conversation.push({
            "request_uuid": request_uuid,
            "prompt": userInput,
            "response": null,
        })
        setTimeout(() => {
            getCompletion(request_uuid, userInput);
        }, 0);
        setCurrentPrompt("");
    }

    return (
        <>
            {conversation.map((conversationItem, index) => {
                console.log(conversationItem);
                if (conversationItem["response"] === null || conversationItem["response"] === undefined) {
                    return (
                        <React.Fragment key={index}>
                            <Card sx={{mb: 2, p: 2}} variant={"outlined"}>{conversationItem["prompt"]}</Card>
                            <Card sx={{mb: 2, p: 2, textAlign: "center"}} variant={"outlined"}>
                                <CircularProgress />
                            </Card>
                        </React.Fragment>
                    );
                } else {
                    if (conversationItem["audio"] === null || conversationItem["audio"] === undefined) {
                        return (
                            <React.Fragment key={index}>
                                <Card sx={{mb: 2, p: 2}} variant={"outlined"}>{conversationItem["prompt"]}</Card>
                                <Card sx={{mb: 2, p: 2}} variant={"outlined"}>
                                    <ReactMarkdown>
                                        {conversationItem["response"]}
                                    </ReactMarkdown>
                                </Card>
                            </React.Fragment>
                        );
                    } else {
                        return (
                            <React.Fragment key={index}>
                                <Card sx={{mb: 2, p: 2}} variant={"outlined"}>{conversationItem["prompt"]}</Card>
                                <Card sx={{mb: 2, p: 2}} variant={"outlined"}>
                                    <ReactMarkdown>
                                        {conversationItem["response"]}
                                    </ReactMarkdown>
                                    {audioElements[index] && <>
                                        <br/><br/>
                                        {audioElements[index]}
                                    </>}
                                </Card>
                            </React.Fragment>
                        );
                    }
                }
            })}
            <Card sx={{mb: 2, p: 2}} variant={"outlined"}>
                <ul>{
                    recommendedUrls.map((recommendedUrl) => {
                        return <li><a href={recommendedUrl.url}>{recommendedUrl.description}</a></li>;
                    })
                }</ul>
            </Card>
            <TextField
                ref={scrollRef}
                id="prompt"
                label="Prompt"
                variant="outlined"
                onChange={(ev) => {
                    setCurrentPrompt(ev.target.value);
                }}
                value={currentPrompt}
                onKeyDown={(ev) => {
                    if (ev.key === "Enter" && currentPrompt.replace(/\s+/g, '').length > 0) {
                        triggerPromptCompletion(currentPrompt);
                        ev.preventDefault();
                    }
                }}
            />
            <AudioRecorder onAudioRecorded={handleAudioRecorded} />
        </>
    )
}
